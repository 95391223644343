<template>
  <div>
    <h2 class="mb-6">Date et heure de rendez-vous</h2>

    <div class="rounded pa-5 blue white--text mb-2 hidden-lg-and-up">
      Faites défiler les colonnes en les faisant glisser avec votre doigt.
    </div>

    <v-row v-if="loading" justify="center" class="my-12"
      ><v-progress-circular color="blue" indeterminate></v-progress-circular
    ></v-row>

    <v-slide-group v-else>
      <v-slide-item v-for="(jour, index) in jours" :key="index">
        <v-card class="ma-4 pb-5" width="150" color="blue lighten-4">
          <v-row class="mt-5 mb-4" justify="center">
            <strong>{{ jour.format("ddd DD MMM") }}</strong>
          </v-row>
          <div class="d-flex justify-center align-center mt-4 flex-column">
            <div
              v-for="creneau in creneauxDuJour(jour.day())"
              :key="creneau.id"
            >
              <v-btn
                v-if="checkDispo(jour, creneau)"
                class="pa-3 my-2 font-weight-bold"
                elevation="1"
                :dark="selectedJour == jour && selectedCreneau == creneau"
                :color="
                  selectedJour == jour && selectedCreneau == creneau
                    ? 'green'
                    : creneau.remplacant
                    ? 'yellow lighten-4'
                    : 'white'
                "
                @click="selectCreneau(jour, creneau)"
                >{{ heureFormatee(creneau)
                }}<span v-if="creneau.remplacant">*</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-slide-item>
      <v-slide-item>
        <v-row class="fill-height" align="center" justify="center">
          <div class="ma-4">Bientôt ...</div>
        </v-row>
      </v-slide-item>
    </v-slide-group>
    <div class="font-italic mt-2">
      *
      <span class="d-inline-block yellow lighten-3">
        les consultations en jaune sont assurées par une collaboratrice
      </span>
    </div>
    <v-fade-transition
      mode="out-in"
      :duration="100"
      v-if="selectedCreneau && selectedJour"
    >
      <DossierMedical @save="save" />
    </v-fade-transition>
  </div>
</template>

<script lang='ts'>
import { db } from "../../main";
import DossierMedical from "./DossierMedical.vue";
import dayjs from "dayjs";
require("dayjs/locale/fr");
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("fr");

export default {
  components: {
    DossierMedical,
  },

  data() {
    return {
      selectedJour: null,
      selectedCreneau: null,
      jours: [],
      loading: true,
    };
  },

  mounted() {
    this.listerJours();
    this.fetchData();
  },

  computed: {
    now() {
      return dayjs().tz("Europe/Paris");
    },
    creneaux() {
      return this.$store.state.creneaux;
    },
    params() {
      return this.$store.state.params;
    },
    consultations() {
      let consultations = [...this.$store.state.consultations];
      consultations.map((c) => {
        c.debutDayjs = dayjs(c.debut.toDate()).second(0).millisecond(0);
        c.finDayjs = dayjs(c.fin.toDate()).second(0).millisecond(0);
        return c;
      });
      return consultations;
    },
  },

  methods: {
    async save(form) {
      const creneau = this.selectedCreneau;
      let date = this.selectedJour;
      // Valider si le creneau est toujours dispo
      if (this.checkDispo(date, creneau)) {
        date = date.minute(creneau.debut.minutes);
        date = date.hour(creneau.debut.heures);
        date = date.second(0);
        form["debut"] = date.add(1, "seconds").toDate();
        form["fin"] = date.add(creneau.duree, "minutes").toDate();
        this.$store.commit("updateConsultation", form);
        await db
          .collection("consultations")
          .add(this.$store.state.consultation);
        this.$store.commit("dossierMedicalRempli");
        this.$store.commit("suivant");
      } else {
        // Si le creneau n'est plus dispo
        this.$swal.fire({
          icon: "warning",
          title: "Date non disponible",
          text: "Mince ! Un autre patient vient de prendre votre créneau de RDV. Vous pouvez cependant en choisir un autre en haut de cette page.",
        });
      }
    },
    selectCreneau(jour, creneau) {
      this.$store.commit("dateChoisie");
      this.selectedCreneau = creneau;
      this.selectedJour = jour;
    },
    async fetchData() {
      this.loading = true;
      const promise1 = this.$store.dispatch("bindCreneaux");
      const promise2 = this.$store.dispatch("bindConsultations");
      const promise3 = this.$store.dispatch("bindParams");
      await Promise.all([promise1, promise2, promise3]);
      this.loading = false;
    },
    listerJours() {
      let jour = this.now;
      while (jour.isBefore(this.now.add(21, "day"))) {
        if (jour.day() != 0) this.jours.push(jour);
        jour = jour.add(1, "day");
      }
    },
    heureFormatee(creneau) {
      let minutes = creneau.debut.minutes.toString();
      if (minutes.length == 1) {
        minutes = "0" + minutes;
      }
      return creneau.debut.heures.toString() + "h" + minutes;
    },
    creneauxDuJour(day) {
      if (day == 0) day = 7;
      return this.creneaux.filter((c) => c.jour == day);
    },
    checkDispo(jour, creneau) {
      const now = dayjs().tz("Europe/Paris");
      let dateDebutConsultation = jour;

      // Vérifier si jour off
      const jourFormate = jour.format("DD/MM/YYYY");
      const testJourOff = this.params.joursOff.filter((j) => j == jourFormate);
      if (testJourOff.length > 0) return false;

      // Vérifier si le créneau est déjà pris
      dateDebutConsultation = dateDebutConsultation.minute(
        creneau.debut.minutes
      );
      dateDebutConsultation = dateDebutConsultation.hour(creneau.debut.heures);
      dateDebutConsultation = dateDebutConsultation.second(0).millisecond(0);
      let dateFinConsultation = dateDebutConsultation.add(
        creneau.duree,
        "minutes"
      );
      dateFinConsultation = dateFinConsultation.second(0).millisecond(0);

      // Vérifier si même jour et heure déjà passée
      if (
        dateDebutConsultation.isSame(now, "day") &&
        this.dayjsIsSameOrBefore(dateDebutConsultation, now.add(1, "hour"))
      ) {
        return false;
      }

      // Faire les tests
      const test1 = this.consultations.filter(
        (c) =>
          this.dayjsIsSameOrBefore(dateDebutConsultation, c.debutDayjs) &&
          this.dayjsIsSameOrAfter(dateFinConsultation, c.finDayjs)
      );
      const test2 = this.consultations.filter(
        (c) =>
          (this.dayjsIsSameOrBefore(dateFinConsultation, c.finDayjs) &&
            this.dayjsIsAfter(dateFinConsultation, c.debutDayjs)) ||
          (this.dayjsIsSameOrAfter(dateDebutConsultation, c.debutDayjs) &&
            this.dayjsIsBefore(dateDebutConsultation, c.finDayjs))
      );
      if (test1.length > 0 || test2.length > 0) return false;
      return true;
    },
    dayjsIsBefore(date1, date2) {
      if (date1 - date2 < 0) return true;
      return false;
    },
    dayjsIsAfter(date1, date2) {
      if (date1 - date2 > 0) return true;
      return false;
    },
    dayjsIsSameOrBefore(date1, date2) {
      if (date1 - date2 <= 0) return true;
      return false;
    },
    dayjsIsSameOrAfter(date1, date2) {
      if (date1 - date2 >= 0) return true;
      return false;
    },
  },
};
</script>

