<template>
  <div class="d-flex py-2 grey--text">
    <v-icon class="mr-5 ml-5" :color="faite ? 'green' : 'grey'">{{
      faite ? "mdi-checkbox-marked-circle-outline" : "mdi-close"
    }}</v-icon>
    <div :class="faite ? 'black--text' : ''">{{ nom }}</div>
  </div>
</template>

<script>
export default {
  props: {
    faite: Boolean,
    nom: String,
  },
};
</script>


