<template>
  <div>
    <h2 class="mb-6">Votre RDV</h2>
    <div>
      <v-container class="pa-5 green white--text rounded mb-12">
        Votre consultation est bien confirmée pour le<br />
        <span class="date-consu"
          >{{ dateConsultation }} à {{ heureConsultation }}</span
        >
      </v-container>
    </div>
    <h2 class="mb-6">Rappels</h2>
    <div>
      <ul>
        <li>
          Toute consultation non honorée sans justification ni annulation 48h au
          préalable pourra vous être facturée, merci
        </li>
        <li>
          Le montant de la consultation est de 50 € (et 40 € pour les -12ans),
          modes de paiement disponibles : chèques, espèces et virement.
        </li>
        <li>
          La prise en charge de la consultation est possible auprès de certaines
          complémentaires santé, renseignez-vous auprès de votre mutuelle.
        </li>
        <li>
          Une facture d’honoraires vous sera délivrée à l’issue de la séance.
        </li>
        <li>
          Veuillez emmener un drap ou DEUX grandes serviettes pour couvrir la
          table (elle mesure 2 mètres...) Nous avons gardé cette habitude plus
          écologique depuis la pandémie ! Merci de votre compréhension.
        </li>
        <li>Durée de la consultation : 45min à 1h (+/- 10 min)</li>
        <li>
          <em
            >Veuillez excuser par avance mon possible retard, la lecture du
            corps et ses maux peut parfois prendre plus de temps que prévu, il
            est important d’accorder le temps nécessaire à chacun, merci.</em
          >
        </li>
        <li>Pas de règlement CB, merci</li>
        <li>
          <strong
            >Annulations par téléphone uniquement au 06 32 51 79 69</strong
          >
        </li>
      </ul>
    </div>

    <!-- <h2 class="mb-6 mt-10">Protocole COVID</h2>
    <div>
      <p>
        Bonjour,<br />
        pour la sécurité de tous, voici le protocole sanitaire à respecter au
        cabinet, merci d'en prendre bonne note:
      </p>
      <p>
      <ul>


        <li>
          Pour les paiements par chèques: merci de bien vouloir le remplir chez
          vous ou d'apporter votre propre stylo, à l’ordre de Mlle Carole
          Alétru.
        </li>
        <li>
            Les WC et salle d'attente sont à nouveau ouverts. Cette dernière se situe désormais dans le couloir suite à l'installation d'une consoeur diététicienne.
          </li>
      </ul></p>

      <p>
        De mon côté je me laverai les mains avant et
        après chaque soin.
      </p>

      <p>
        Si vous constatez des signes infectieux du COVID19 dans les 15j suivant
        la séance merci de m'en avertir immédiatement!
      </p>
    </div> -->
    <div class="d-flex justify-end">
      <v-btn color="green" dark depressed @click="valider"
        >J'ai compris <v-icon class="ml-3">mdi-check</v-icon></v-btn
      >
    </div>

    <!-- / Navbar  -->
  </div>
</template>

<script>
import dayjs from "dayjs";
require("dayjs/locale/fr");
dayjs.locale("fr");

export default {
  mounted() {
    scroll(0, 0);
  },

  computed: {
    dateConsultation() {
      return dayjs(this.$store.state.consultation.debut).format(
        "dddd DD MMMM YYYY"
      );
    },
    heureConsultation() {
      return dayjs(this.$store.state.consultation.debut).format("HH:mm");
    },
  },

  methods: {
    valider() {
      this.$store.commit("rappelsLus");
      this.$swal.fire({
        icon: "success",
        title: "Merci",
        text:
          "Votre consultation est bien validée pour le " +
          this.dateConsultation +
          " à " +
          this.heureConsultation +
          ". Veillez à bien lire l'ensemble des rappels, vous pourrez ensuite librement fermer cet onglet. Merci et à bientôt.",
      });
    },
  },
};
</script>

<style>
.date-consu {
  font-size: 25px;
  font-weight: 700;
}
</style>
