import Vue from 'vue';
import { vuexfireMutations, firestoreAction } from 'vuexfire';
import Vuex from 'vuex';
import { db } from '../main';
import dayjs from 'dayjs';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    etape: 1,
    questionnaireCovidRempli: false,
    motifDeConsultationRempli: false,
    dateChoisie: false,
    dossierMedicalRempli: false,
    rappelsLus: false,
    consultation: {},
    creneaux: [],
    consultations: [],
    params: {},
  },
  mutations: {
    ...vuexfireMutations,
    suivant(state) {
      state.etape++;
    },
    precedent(state) {
      state.etape--;
    },
    setEtape(state, etape) {
      state.etape = etape;
    },
    questionnaireCovidRempli(state) {
      state.questionnaireCovidRempli = true;
    },
    motifRempli(state) {
      state.motifDeConsultationRempli = true;
    },
    dossierMedicalRempli(state) {
      state.dossierMedicalRempli = true;
    },
    dateChoisie(state) {
      state.dateChoisie = true;
    },
    rappelsLus(state) {
      state.rappelsLus = true;
    },
    updateConsultation(state, valeur) {
      state.consultation = { ...state.consultation, ...valeur };
    },
  },
  actions: {
    bindCreneaux: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        'creneaux',
        db
          .collection('creneaux')
          .orderBy('debut.heures')
          .orderBy('debut.minutes')
      );
    }),
    bindConsultations: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        'consultations',
        db
          .collection('consultations')
          .where('debut', '>=', dayjs().startOf('day').toDate())
      );
    }),
    bindParams: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        'params',
        db.collection('parametres').doc('parametres')
      );
    }),
  },
  modules: {},
});
