import Vue from 'vue';
import VueRouter from 'vue-router';
import FrontendLayout from '../views/layouts/FrontendLayout';
import AdminLayout from '../views/layouts/AdminLayout';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'prise-de-rdv',
    // redirect: 'prise-de-rdv',
    component: FrontendLayout,
    // children: [
    //   {
    //     path: '/prise-de-rdv',
    //     name: 'prise-de-rdv',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/frontend/PriseDeRDV.vue'),
    //   },
    // ],
  },
  {
    path: '/admin/login',
    name: 'login',
    meta: {
      requiresVisitor: true,
    },
    component: () => import('../views/admin/auth/Login.vue'),
  },
  {
    path: '/admin',
    redirect: '/admin/planning',
    component: AdminLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/admin/planning',
        name: 'planning',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/Planning.vue'),
      },
      {
        path: '/admin/rdv/:id',
        name: 'fiche-rdv',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/rdv/FicheRDV.vue'),
      },
      {
        path: '/admin/creneaux',
        name: 'creneaux',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/creneaux/Creneaux.vue'),
      },
      {
        path: '/admin/creneaux/create',
        name: 'creneaux.create',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/creneaux/CreateCreneau.vue'),
      },
      {
        path: '/admin/creneaux/:id/edit',
        name: 'creneaux.edit',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/creneaux/EditCreneau.vue'),
      },
      {
        path: '/admin/parametres',
        name: 'parametres',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/Parametres.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
