<template>
  <div>
    <h2 class="mb-6">Motif de consultation</h2>
    <em
      >En cas de déformation ou d'omission volontaire à propos du motif de
      consultation, je me réserve le droit de vous réorienter vers un médecin,
      de vous refuser les soins ostéopathiques, ceci tout en vous facturant la
      séance. Merci de votre compréhension.</em
    >
    <!-- Question motif  -->
    <form class="v-form" @submit.prevent="suivant">
      <div class="mt-8 mb-3">
        <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
        Veuillez sélectionner un motif dans le menu déroulant ci-dessous
      </div>
      <v-select
        v-model="form.motif"
        :items="motifs"
        label="Motif de consultation"
        outlined
        required
      ></v-select>

      <!-- Question grossesse  -->
      <div v-if="form.motif == 'Suivi de grossesse'">
        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Veuillez précisez le terme en semaines d'aménorrhée
        </div>
        <v-text-field
          type="number"
          v-model="form.terme"
          step="1"
          outlined
          label="Terme"
          required
        ></v-text-field>
      </div>

      <!-- Question douleur articulaire  -->
      <div v-if="form.motif == 'Douleur articulaire'">
        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Veuillez préciser l'articulation concernée
        </div>
        <v-text-field
          outlined
          v-model="form.articulationConcernee"
          label="Articulation concernée"
          required
        ></v-text-field>
      </div>

      <!-- Question Maux de dos  -->
      <div v-if="form.motif == 'Maux de dos'">
        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Veuillez préciser la zone concernée
        </div>
        <v-text-field
          outlined
          v-model="form.zoneConcernee"
          label="Zone concernée"
          required
        ></v-text-field>
      </div>

      <!-- Question Tendinite  -->
      <div v-if="form.motif == 'Tendinite'">
        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Veuillez préciser le muscle ou la zone concernée
        </div>
        <v-text-field
          outlined
          v-model="form.muscleOuZoneConcernee"
          label="Muscle ou zone concernée"
          required
        ></v-text-field>
      </div>

      <!-- Question Sciatique, cruralgie  -->
      <div
        v-if="
          form.motif == 'Sciatique, cruralgie' ||
          form.motif == 'Névralgie cervico-brachiale, canal carpien'
        "
      >
        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Ressentez vous des fourmillements dans le bras ou la main depuis
          l’apparition de la douleur, ou bien une impossibilité à bouger le bras
          ou la main depuis l’apparition de cette douleur ?
        </div>
        <v-radio-group v-model="form.fourmillements" row>
          <v-radio label="Oui" value="Oui"></v-radio>
          <v-radio label="Non" value="Non"></v-radio>
        </v-radio-group>
      </div>

      <!-- Entorse  -->
      <div v-if="form.motif == 'Entorse'">
        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Quelle est la date du traumatisme ?
        </div>
        <v-date-picker
          v-model="form.dateEntorse"
          :max="dateMax"
          full-width
        ></v-date-picker>
        <div class="font-italic">
          Les rendez-vous ne sont disponibles que 3 semaines après le
          traumatisme afin de laisser le temps aux ligaments de cicatriser et
          éviter d'aggraver la blessure en manipulant. Merci de votre
          compréhension.
        </div>
      </div>

      <!-- Maux de tête, ventre et vertiges  -->
      <div
        v-if="
          form.motif == 'Maux de tête' ||
          form.motif == 'Maux de ventre' ||
          form.motif == 'Vertiges'
        "
      >
        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          <span v-if="form.motif == 'Vertiges'"
            >Cette crise est pour vous ...</span
          >
          <span v-else>Cette douleur est pour vous une douleur ...</span>
        </div>
        <v-radio-group v-model="form.habituelle" row>
          <v-radio label="habituelle" value="habituelle"></v-radio>
          <v-radio label="inhabituelle" value="inhabituelle"></v-radio>
        </v-radio-group>
      </div>

      <!-- Douleur cage thoracique  -->
      <div v-if="form.motif == 'Douleur cage thoracique'">
        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          La douleur est-elle accompagnée de :
        </div>
        <v-switch
          v-model="form.douleurCageThoracique"
          label="Sensation d'angoisse"
          value="Sensation d'angoisse"
        ></v-switch>
        <v-switch
          v-model="form.douleurCageThoracique"
          label="Palpitations"
          value="Palpitations"
        ></v-switch>
        <v-switch
          v-model="form.douleurCageThoracique"
          label="Toux inhabituelle"
          value="Toux inhabituelle"
        ></v-switch>
        <v-switch
          v-model="form.douleurCageThoracique"
          label="Essouflement"
          value="Essouflement"
        ></v-switch>
      </div>

      <!-- Autre motif  -->
      <div v-if="form.motif == 'Autre motif'">
        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Veuillez préciser le motif
        </div>
        <v-textarea
          outlined
          v-model="form.autreMotif"
          label="Votre motif de consultation"
          required
        ></v-textarea>
      </div>

      <div class="d-flex mt-8">
        <v-spacer></v-spacer>
        <v-btn color="green" dark depressed type="submit"
          >Suivant <v-icon class="ml-3">mdi-arrow-right-bold</v-icon></v-btn
        >
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import dayjs from "dayjs";

export default {
  data() {
    return {
      message: "",
      form: {
        motif: "",
        articulationConcernee: "",
        terme: null,
        fourmillements: "",
        zoneConcernee: "",
        muscleOuZoneConcernee: "",
        dateEntorse: "",
        habituelle: "",
        douleurCageThoracique: [],
        autreMotif: ""
      },
      motifs: [
        "Checkup / Suivi",
        "Suivi de grossesse",
        "Suite de consultation il y a moins de 2 semaines",
        "Douleur articulaire",
        "Sciatique, cruralgie",
        "Névralgie cervico-brachiale, canal carpien",
        "Maux de dos",
        "Douleurs cervicales",
        "Tendinite",
        "Entorse",
        "Maux de tête",
        "Maux de ventre",
        "Vertiges",
        "Douleur cage thoracique",
        "Chute",
        "Accident de la route",
        "Consultation bébé <18 mois",
        "Autre motif"
      ]
    };
  },

  computed: {
    disabled() {
      return this.form.motif == "";
    },
    dateMax() {
      return dayjs().format("YYYY-MM-DD");
    }
  },

  mounted() {
    this.form.motif = this.$store.state.consultation.motif || "";
    this.form.articulationConcernee =
      this.$store.state.consultation.articulationConcernee || "";
    this.form.terme = this.$store.state.consultation.terme || null;
    this.form.fourmillements =
      this.$store.state.consultation.fourmillements || "";
    this.form.zoneConcernee =
      this.$store.state.consultation.zoneConcernee || "";
    this.form.muscleOuZoneConcernee =
      this.$store.state.consultation.muscleOuZoneConcernee || "";
    this.form.dateEntorse = this.$store.state.consultation.dateEntorse || "";
    this.form.habituelle = this.$store.state.consultation.habituelle || "";
    this.form.douleurCageThoracique =
      this.$store.state.consultation.douleurCageThoracique || [];
    this.form.autreMotif = this.$store.state.consultation.autreMotif || "";
  },

  methods: {
    error(message) {
      this.message = message;
      if (!message)
        this.message =
          "Étant donné votre motif de consultation, veuillez me contacter par téléphone pour prendre RDV.";
      this.$swal.fire({
        icon: "warning",
        title: "Attention",
        text: this.message
      });
    },
    suivant() {
      // Check si suivi a été choisi
      if (this.form.motif == "") {
        this.error(
          "Veuillez choisir un motif de consultation pour poursuivre."
        );
        return;
      }

      // Check fourmillement sciatique && Névralgie
      if (
        this.form.motif == "Sciatique, cruralgie" ||
        this.form.motif == "Névralgie cervico-brachiale, canal carpien"
      ) {
        if (this.form.fourmillements == "") {
          this.error("Il manque des informations.");
          return;
        }
        if (this.form.fourmillements == "Oui") {
          this.error();
          return;
        }
      }

      // Check fourmillement sciatique && Névralgie
      if (
        this.form.motif == "Maux de tête" ||
        this.form.motif == "Vertiges" ||
        this.form.motif == "Maux de ventre"
      ) {
        if (this.form.habituelle == "") {
          this.error("Il manque des informations.");

          return;
        }
        if (this.form.habituelle == "inhabituelle") {
          this.error();
          return;
        }
      }

      // Douleur cage thoracique
      if (this.form.motif == "Douleur cage thoracique") {
        if (this.form.douleurCageThoracique.length > 0) {
          this.error();
          return;
        }
      }

      // Chute &  accident de la route
      if (
        this.form.motif == "Chute" ||
        this.form.motif == "Accident de la route"
      ) {
        this.error();
        return;
      }

      // Consultation bébé <18 mois
      if (this.form.motif == "Consultation bébé <18 mois") {
        this.error(
          "Pour toute prise de RDV pour des bébés de moins de 18 mois, veuillez me contacter par téléphone au 06 32 51 79 69 et le mentionner dans votre message vocal. Je reprendrai contact avec vous. Merci."
        );
        return;
      }

      // Valider
      this.$store.commit("updateConsultation", this.form);
      if (
        this.form.motif == "Checkup / Suivi" ||
        this.form.motif == "Suite de consultation il y a moins de 2 semaines"
      ) {
        this.$store.commit("setEtape", 3);
        this.$store.commit("motifRempli");
      } else {
        this.$store.commit("suivant");
      }
    }
  }
};
</script>

