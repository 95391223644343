export const operations = [
  'Appendicectomie',
  'Arthroscopie',
  'Ligamentoplastie',
  'Extraction dents de sagesse',
  'Ablation végétations-amygdales',
  'Ablation de hernie',
  'Ablation de kystes',
  'Ablation tumeur',
  'Ablation grain de beauté',
  'Césarienne',
  'Curetage',
  'Intervention prostate',
  'Coloscopie, rectoscopie ou fibroscopie ',
  'Ablation de mélanome ou carcinome',
  'Ablation vésicule biliaire',
  'Colique néphrétique',
  'Plaies graves/Points de suture',
  'Laser yeux',
  'Varicectomie',
  'Libération du canal carpien (indiquer les résultats avec la date)',
  'Stent ou pontage',
  'Pose pacemaker',
  'Kyste sacro-coccygien',
  'Pose de diabolos',
  'Ongle incarné',
  'Panaris',
  'Infiltration',
  'Extraction dentaire',
  'Opération des sinus',
  'Pose d’implant',
  'Pose de prothèse',
  'Chirurgie esthétique',
  'Greffe',
  'Hystérectomie',
  'Ectopie testiculaire',
  'Bandelettes urinaires',
  'Autre (à préciser avec la date ci-dessous)',
];

export const traumatismes = [
  'Fractures',
  'Entorses',
  'Luxations',
  'Accident de la voie publique (piéton, 2 roues, voiture, poids lourd ...)',
  'Chutes ou événements traumatiques marquants (escaliers,vélo, cheval, arbre échelle, escabeau, chaise, verglas, traumatisme crânien, sport…)',
  'Traumatisme crânien',
  'Choc psychologique',
];

export const antecedentsMedicaux = [
  'Accidents de travail',
  'Hospitalisation',
  'Malaises, perte de connaissance, coma…',
  'Migraines, maux de tête',
  'Brûlures de la peau importantes',
  'Problèmes auditifs (perte d’audition, acouphènes)',
  'Troubles ORL (vertiges, angines sinusites, otites, cancer, ...)',
  'Allergies',
  'Soins dentaires (couronnes, plombages, bridges, pivots, parodontite, cancers de la bouche, ...)',
  'Orthodontie',
  'Troubles respiratoire (asthme, bronchite, pneumonie, toux, essoufflement, insuffisance, cancer, )',
  'Maladie de Crohn ou Rectocolite Hémorragique',
  'Intolérances alimentaires',
  'Anomalies sanguines: anémie, ferritine, cholestérol, diabète, thyroïde, taux de PSA',
  'Leucémie ou Lymphome',
  'Pathologies hormonales (diabète, thyroïde, trouble des surrénales, troubles hypophysaires …)',
  'Troubles urinaires (cystite, pyélonéphrite, coliques néphrétiques, insuffisance rénale cancer, …)',
  'Troubles musculo-squelettiques (tendinites, déchirures musculaires, crampes, torticolis, lumbago, douleurs articulaires, cancer, ...).',
  'Névralgies (névralgie d’Arnold, sciatique, cruralgie, névralgie cervico-brachiale, défilé du canal carpien, défilé du nerf ulnaire, névralgie intercostale, ...)',
  'Problèmes articulaires (arthrose, arthrite, polyarthrite, hallux valgus, scoliose, maladie de Forestier, Maladie de Scheuermann, goutte…)',
  'Fibromyalgie',
  'Mauvaise qualité de sommeil/troubles du sommeil',
  'Problèmes dermatologiques (urticaires, eczéma, psoriasis varicelle,acnée,  zona, dartre, gale, herpès, cancer, ...)',
  'Maladies infectieuses (roséole, rougeole, oreillons, scarlatine, bronchiolite, coqueluche, toxoplasmose, mononucléose, maladies tropicales)',
  'Tabagisme',
  'Alcoolisme',
  'Autre addiction (drogues, jeux, …)',
  'Troubles cardiaques (Hypertension, hypotension, valvulopathie/souffle cardiaque, trouble du rythme, insuffisance)',
  'Troubles circulatoires ou de la coagulation (artérite, varices, phlébite, œdèmes, jambes lourdes, impatiences, hémorroïdes, maladie de Willebrand)',
  'Troubles digestifs (acidité, ballonnements, gazs, transit perturbé, troubles du foie, du pancréas ou de la vésicule biliaire, cancers)',
  'Troubles des acquisitions (apprentissage de la marche, de la parole de l’écriture, intégration des aliments, orthophonie, orthopsie)',
  'Troubles psychologiques et pathologies psychiatriques (Chocs psychiques, dépression, burn-out, TOC, bipolarité, phobies, événements traumatisants…)',
  'Troubles neurologiques (tremblements, démence, Sclérose en plaque, hémiplégie, Maladie de Parkinson, Maladie d’Alzheimer, tumeur, ...)',
  'Troubles gynécologiques (utérus trompes, ovaires, vagin, cancers de la femme, endométriose, ...) ',
  'Troubles urinaires (fuites, inconforts, ...)',
  'Troubles prostatiques et testiculaire (adénome, torsion, cancer,…)',
];

export const examens = [
  'Bilan sanguin',
  'Électrocardiogramme',
  'Test d’effort',
  'Echographie',
  'Radiographie',
  'Scanner',
  'IRM',
  'Mammographie',
  'Électroencéphalogramme',
  'Ostéodensitométrie',
  'Scintigraphie',
  'Angiographie',
  'Echo-Doppler',
  'TEP-Scan',
  'Dépistage Hémoccult®️ (contre le cancer colo-rectal)',
  'Dépistage mammographique',
  'Frottis cervical (contre le cancer du col de l’utérus)',
];

export const infosAncienPatient = [
  'Adresse',
  'Médecin traitant',
  'Situation maritale',
  'Poids',
  'Taille',
  'Nom de famille',
  'Sexe',
  'Téléphone',
  'Adresse e-mail',
  'Traitements médicamenteux (nouveau traitement, arrêt traitement ou changements de dosages?)',
  'Dispositifs médicaux (renouvellements de semelles ou de verres de lunettes. Nouveaux dispositifs apnée du sommeil, appareils auditifs, bas/chaussette de contention, matériel médicalisé, ...)',
  'Changement emploi ou études',
  'Changements alimentation',
  'Changement sport et loisir',
  'Opérations chirurgicales',
  'Consultations médicales',
  'Hospitalisation',
  'Examens complémentaires (radio, scanner, IRM, échographie, Doppler, …)',
  'Examens de dépistages (frottis, PSA, mammographie, Hémocult®️, coloscopie/fibroscopie)',
  'Traumatismes (chutes, accidents, fractures, entorses, )',
  'Pathologies médicales',
  'Evénements de santé au niveau familial',
  'Suivi grossesse',
];
