import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

Vue.use(VueSweetalert2);

Vue.config.productionTip = false;
firebase.initializeApp({
  apiKey: 'AIzaSyAVHi7cac7XaQqcn5Ov883zUag7bgWXNFU',
  authDomain: 'carole-ac7bc.firebaseapp.com',
  projectId: 'carole-ac7bc',
  storageBucket: 'carole-ac7bc.appspot.com',
  messagingSenderId: '815424018992',
  appId: '1:815424018992:web:d64533673d185b18906bf5',
  measurementId: 'G-DVTFMMF5TE',
});

export const db = firebase.firestore();
export const auth = firebase.auth();
export const functions = firebase.app().functions('europe-west1');
//  ! Retirer émulateurs
// functions.useEmulator('localhost', 5001);
// db.useEmulator('localhost', 8181);

// middleware
router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!currentUser) {
      next({
        name: 'login',
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (currentUser) {
      next({
        name: 'planning',
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

let app = '';

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      vuetify,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
