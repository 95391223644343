<template>
  <v-app>
    <div v-if="!loading">
      <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.mdAndUp"
        app
      >
        <v-list nav>
          <v-list-item :to="{ name: 'planning' }">
            <v-list-item-action>
              <v-icon color="green">mdi-calendar-clock</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Planning</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'creneaux' }">
            <v-list-item-action>
              <v-icon color="blue">mdi-clock-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Créneaux</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'parametres' }">
            <v-list-item-action>
              <v-icon color="brown">mdi-cog</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Paramètres</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar clipped-left app color="blue" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="ml-0 pl-4">{{ titre }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- Log out -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="logout">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>Déconnexion</span>
        </v-tooltip>
      </v-app-bar>

      <v-main class="ma-4 mb-12">
        <v-fade-transition mode="out-in" :duration="100">
          <router-view @setTitre="setTitre" v-if="!loading"></router-view>
        </v-fade-transition>
      </v-main>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { auth } from "@/main";

export default {
  name: "Navigationlayout",

  components: {},

  data: () => ({
    drawer: null,
    titre: "",
    loading: true,
  }),

  mounted() {
    this.fetchData();
  },

  computed: {},

  methods: {
    setTitre(titre) {
      this.titre = titre;
    },
    async fetchData() {
      this.loading = true;
      const promise1 = this.$store.dispatch("bindConsultations");
      const promise2 = this.$store.dispatch("bindParams");
      const promise3 = this.$store.dispatch("bindCreneaux");
      await Promise.all([promise1, promise2, promise3]);
      this.loading = false;
    },
    async logout() {
      await auth.signOut();
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style>
table tr {
  cursor: pointer;
}
</style>
