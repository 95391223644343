<template>
  <v-app
    :style="{
      background: $vuetify.theme.themes[theme].background,
      'font-family': $vuetify.theme.themes[theme].fontFamily,
    }"
  >
    <v-app-bar app elevate-on-scroll color="blue">
      <v-container>
        <v-toolbar-title class="font-weight-bold white--text"
          >Carole ALÉTRU - Prise de rendez-vous</v-toolbar-title
        >
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row>
          <v-col col="12" md="4" xl="3">
            <v-sheet color="white" class="rounded-lg pa-4"
              ><h3 class="mb-4">Étapes</h3>
              <!-- Etape questionnaire covid -->
              <!-- <EtapeItem
                :faite="questionnaireCovidRempli"
                nom="Questionnaire COVID-19"
              /> -->
              <!-- Etape motif de consultation -->
              <EtapeItem
                :faite="motifDeConsultationRempli"
                nom="Motif de consultation"
              />
              <!-- Date avec agenda -->
              <EtapeItem
                :faite="dateChoisie"
                nom="Choix de la date et de l'heure"
              />
              <!-- Dossier médical  -->
              <EtapeItem :faite="dossierMedicalRempli" nom="Dossier médical" />
              <!-- Rappel et protocole covid -->
              <EtapeItem :faite="rappelsLus" nom="Rappels et protocole" />
            </v-sheet>
          </v-col>
          <v-col col="12" md="8" xl="9">
            <v-sheet class="rounded-lg white pa-4 mb-8">
              <!-- Questionnaire covid -->

              <!-- Motif de consultation -->
              <v-fade-transition
                mode="out-in"
                :duration="100"
                v-if="etape == 1"
              >
                <MotifDeConsultation />
              </v-fade-transition>

              <!-- Description du motif de consultation -->
              <v-fade-transition
                mode="out-in"
                :duration="100"
                v-if="etape == 2"
              >
                <DescriptionMotifDeConsultation />
              </v-fade-transition>

              <!-- Description du motif de consultation -->
              <v-fade-transition
                mode="out-in"
                :duration="100"
                v-if="etape == 3"
              >
                <ChoixDate />
              </v-fade-transition>

              <!-- Rappel et protocole COVID -->
              <v-fade-transition
                mode="out-in"
                :duration="100"
                v-if="etape == 4"
              >
                <Rappels />
              </v-fade-transition>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import MotifDeConsultation from "../frontend/MotifDeConsultation";
import ChoixDate from "../frontend/ChoixDate";
import Rappels from "../frontend/Rappels";
import DescriptionMotifDeConsultation from "../frontend/DescriptionMotifDeConsultation";
import EtapeItem from "../../components/EtapeItem";

export default {
  components: {
    MotifDeConsultation,
    EtapeItem,
    ChoixDate,
    DescriptionMotifDeConsultation,
    Rappels,
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    etape() {
      return this.$store.state.etape;
    },
    questionnaireCovidRempli() {
      return this.$store.state.questionnaireCovidRempli;
    },
    motifDeConsultationRempli() {
      return this.$store.state.motifDeConsultationRempli;
    },
    dateChoisie() {
      return this.$store.state.dateChoisie;
    },
    dossierMedicalRempli() {
      return this.$store.state.dossierMedicalRempli;
    },
    rappelsLus() {
      return this.$store.state.rappelsLus;
    },
  },
  data: () => ({}),
};
</script>


