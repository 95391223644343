<template>
  <div class="pb-3">
    <v-form @submit.prevent="save" ref="form" lazy-validation>
      <v-divider class="my-6"></v-divider>
      <h2 class="mb-5">Dossier médical</h2>
      <v-select
        outlined
        label="Le ou la patiente est ..."
        v-model="form.nouveauPatient"
        :items="nouveauPatientItems"
      ></v-select>

      <!-- Bloc nouveau patient -->
      <div v-if="form.nouveauPatient == true">
        <div class="mb-2 font-italic">
          Veuillez remplir le questionnaire de renseignements suivant. Cela ne
          vous prendra que quelques minutes et me permettra de comprendre
          comment vous fonctionnez, car souvent les événements du passé ont
          encore un impact sur le présent… Mais aussi de pouvoir échanger avec
          les autres intervenants de santé à propos de votre prise en charge si
          besoin.<br />Merci d’avance de le remplir le plus consciencieusement
          possible afin de vous prodiguer des soins personnalisés.<br />L'ensemble
          de vos données seront stockées par un hébergeur agréé pour données de
          santé (HDS) par le ministère de la santé.
        </div>
        <h3 class="mt-8">Informations administratives</h3>
        <v-row>
          <!-- Nom -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Nom du ou de la patiente
            </div>
            <v-text-field
              outlined
              v-model="form.nom"
              :rules="required"
            ></v-text-field>
          </v-col>

          <!-- Prenom-->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Prénom du ou de la patiente
            </div>
            <v-text-field
              outlined
              v-model="form.prenom"
              :rules="required"
            ></v-text-field>
          </v-col>

          <!-- Sexe  -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Votre sexe
            </div>
            <v-select
              :items="sexeItems"
              outlined
              label="Sexe"
              v-model="form.sexe"
              :rules="required"
            ></v-select>
          </v-col>

          <!-- Date de naissance  -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Votre date de naissance
            </div>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateDeNaissanceFormatee"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  label="Date"
                  v-bind="attrs"
                  v-on="on"
                  :rules="required"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="form.dateDeNaissance"
                :max="new Date().toISOString().substr(0, 10)"
                min="1930-01-01"
                @change="saveDOB"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- Situation maritale -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Situation maritale
            </div>
            <v-text-field
              outlined
              v-model="form.situationMaritale"
              :rules="required"
            ></v-text-field>
          </v-col>

          <!-- Taille -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Taille
            </div>
            <v-text-field
              outlined
              v-model="form.taille"
              :rules="required"
            ></v-text-field>
          </v-col>

          <!-- Poids -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Poids
            </div>
            <v-text-field
              outlined
              v-model="form.poids"
              :rules="required"
            ></v-text-field>
          </v-col>

          <!-- Adresse -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Votre adresse postale
            </div>
            <v-textarea
              outlined
              v-model="form.adresse"
              rows="3"
              :rules="required"
            ></v-textarea>
          </v-col>

          <!-- Numéro de téléphone -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Numéro de téléphone portable
            </div>
            <v-text-field
              outlined
              v-model="form.telephone"
              :rules="required"
            ></v-text-field>
          </v-col>

          <!-- Email -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Adresse email
            </div>
            <v-text-field
              type="email"
              outlined
              v-model="form.email"
              :rules="required"
            ></v-text-field>
          </v-col>

          <!-- Emploi actuel -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Votre emploi ou études actuels
            </div>
            <v-text-field
              outlined
              v-model="form.emploi"
              :rules="required"
            ></v-text-field>
          </v-col>

          <!-- Emplois précédents -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Vos emplois précédents
            </div>
            <v-text-field
              outlined
              v-model="form.emploisPrecedents"
              :rules="required"
            ></v-text-field>
          </v-col>

          <!-- Médecin traitant -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Votre médecin traitant
            </div>
            <v-text-field
              outlined
              v-model="form.medecinTraitant"
              :rules="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- **** Traitements en cours ***** -->
        <h3 class="mt-10 mb-3">Traitements en cours</h3>
        <v-row>
          <!-- Médicamenteux -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Indiquez les traitements médicamenteux que vous prenez en ce
              moment et la maladie traitée (médicaments, injections,
              inhalateurs, homéopathie, phytothérapie, aromathérapie,
              compléments alimentaires, …)
            </div>
            <v-textarea
              outlined
              rows="3"
              v-model="form.medicamenteux"
              placeholder="Exemple : homéopathie pour de l'asthme"
            ></v-textarea>
          </v-col>

          <!-- dispositifs Medicaux  -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Indiquez ici vos dispositifs médicaux (semelles orthopédiques,
              lunettes, appareils auditifs, apnée du sommeil, TENS,
              bas/chaussettes de contention, canne, déambulateur, lit
              médicalisé... )
            </div>
            <v-textarea
              outlined
              v-model="form.dispositifsMedicaux"
              rows="3"
            ></v-textarea>
          </v-col>

          <!-- Prend contraception ?  -->
          <v-col cols="12" md="6" class="mycol" v-if="form.sexe == 'Femme'">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Avez-vous une contraception ?
            </div>
            <v-select
              :rules="required"
              outlined
              :items="['Oui', 'Non']"
              v-model="form.prendContraception"
            ></v-select>
          </v-col>

          <!-- Contraception  -->
          <v-col
            cols="12"
            md="6"
            class="mycol"
            v-if="form.sexe == 'Femme' && form.prendContraception == 'Oui'"
          >
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Laquelle ?
            </div>
            <v-text-field
              :rules="required"
              outlined
              v-model="form.contraception"
              placeholder="Stérilet, pilule..."
            ></v-text-field>
          </v-col>

          <!-- Raison pas de contraception  -->
          <v-col
            cols="12"
            md="6"
            class="mycol"
            v-if="form.sexe == 'Femme' && form.prendContraception == 'Non'"
          >
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Pour quelle raison ?
            </div>
            <v-select
              :items="['Désir de grossesse', 'Ménopause', 'Choix personnel']"
              outlined
              :rules="required"
              v-model="form.raisonNonContraception"
            ></v-select>
          </v-col>

          <!-- Date dernières règles  -->
          <v-col
            cols="12"
            md="6"
            class="mycol"
            v-if="
              form.sexe == 'Femme' &&
              form.prendContraception == 'Non' &&
              form.raisonNonContraception == 'Désir de grossesse'
            "
          >
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Date de vos dernières règles
            </div>
            <v-text-field
              type="date"
              outlined
              :rules="required"
              v-model="form.dateDernieresRegles"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- **** Habitudes de vie ***** -->
        <h3 class="mt-10 mb-3">Habitudes de vie</h3>
        <v-row>
          <!-- Entreprise ou établissement scolaire  -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Entreprise / établissement scolaire
            </div>
            <v-text-field
              outlined
              :rules="required"
              v-model="form.entreprise"
            ></v-text-field>
          </v-col>

          <!-- Sports actuels  -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Sports actuels
            </div>
            <v-text-field
              outlined
              :rules="required"
              v-model="form.sportsActuels"
            ></v-text-field>
          </v-col>

          <!-- Sports passés  -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Sports passés
            </div>
            <v-text-field
              outlined
              :rules="required"
              v-model="form.sportsPasses"
            ></v-text-field>
          </v-col>

          <!-- loisir et hobbies  -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Loisirs et hobbies
            </div>
            <v-text-field
              outlined
              :rules="required"
              v-model="form.loisirs"
            ></v-text-field>
          </v-col>

          <!-- Droitier ou Gaucher  -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Droitier ou gaucher
            </div>
            <v-select
              outlined
              :rules="required"
              :items="['Droitier', 'Gaucher', 'Ambidextre']"
              v-model="form.droitierGaucher"
            ></v-select>
          </v-col>
        </v-row>

        <!-- **** Alimentation et hydratation ***** -->
        <h3 class="mt-10 mb-3">Alimentation et hydratation</h3>
        <v-row>
          <!-- type d'alimentation -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Type d’alimentation
            </div>
            <v-textarea
              outlined
              rows="2"
              :rules="required"
              v-model="form.typeAlimentation"
              placeholder="Salé? sucré? resaler les plats? Goût pour la charcuteries, les fromages, les gâteaux, les sucreries? Végétarien, végétalien? Plats tout faits/sandwiches? Cuisine maison?"
            ></v-textarea>
          </v-col>

          <!-- type de boissons -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Type de boissons consommées (veuillez préciser les quantités par
              jour ou semaine, même approximatives)
            </div>
            <v-textarea
              outlined
              rows="2"
              :rules="required"
              v-model="form.typeBoissons"
              placeholder=" Consommation de thé? café? Sodas? Jus? Sirops? Lait? Bières? Vin à table? Apéritifs?"
            ></v-textarea>
          </v-col>

          <!-- Suivi diet -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Suivi auprès d’un professionnel de la nutrition?
            </div>
            <v-select
              outlined
              :items="['Oui', 'Non']"
              :rules="required"
              v-model="form.suiviDiet"
            ></v-select>
          </v-col>

          <!-- Variations de poids importantes ? -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Variations de poids importantes dans votre vie?
            </div>
            <v-text-field
              outlined
              :rules="required"
              v-model="form.variationsImportantesPoids"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- **** Antécédents chirurgicaux ***** -->
        <h3 class="mt-10 mb-3">Antécédents chirurgicaux</h3>

        <v-row>
          <!-- Opérations  -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Avez-vous subi certaines de ces opérations, si oui à quelle date ?
            </div>
            <v-select
              outlined
              v-model="form.operationsFaites"
              :items="operationsItems"
              multiple
            >
            </v-select
          ></v-col>

          <!-- Opérations  -->
          <v-col
            cols="12"
            class="mycol"
            v-if="form.operationsFaites.length > 0"
          >
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Précisez les dates des opérations cochées
            </div>
            <v-text-field
              outlined
              v-model="form.datesOperations"
              placeholder="Ex: dents de sagesses le 01/01/21"
            >
            </v-text-field
          ></v-col>
        </v-row>

        <!-- **** Antécédents traumatiques ***** -->
        <h3 class="mt-10 mb-3">Antécédents traumatiques</h3>

        <v-row>
          <!-- Traumatismes  -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Avez-vous subi certains de ces traumatismes, si oui à quelle date
              svp? (même approximative)
            </div>
            <v-select
              outlined
              v-model="form.traumatismesSubis"
              :items="traumatismesItems"
              multiple
            >
            </v-select
          ></v-col>

          <!-- Opérations  -->
          <v-col
            cols="12"
            class="mycol"
            v-if="form.traumatismesSubis.length > 0"
          >
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Précisez les dates des traumatismes cochés
            </div>
            <v-text-field
              outlined
              v-model="form.datesTraumatismes"
              placeholder="Ex: fracture du tibia le 01/01/21"
            >
            </v-text-field
          ></v-col>
        </v-row>

        <!-- **** Antécédents médicaux ***** -->
        <h3 class="mt-10 mb-3">Antécédents médicaux</h3>
        <v-row>
          <!-- Problèmes  -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Avez-vous ou avez-vous eu certains de ces problèmes/évènements
              médicaux?
            </div>
            <v-select
              outlined
              v-model="form.antecedentsMedicaux"
              :items="antecedentsMedicauxItems"
              multiple
            >
            </v-select
          ></v-col>

          <!-- Age des premières règles  -->
          <v-col
            cols="12"
            md="6"
            class="mycol"
            v-if="form.sexe == 'Femme' && age >= 15"
          >
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Age des premières règles
            </div>
            <v-text-field
              v-model="form.agePremieresRegles"
              :rules="required"
              outlined
            ></v-text-field>
          </v-col>

          <!-- Age ménopause  -->
          <v-col
            cols="12"
            md="6"
            class="mycol"
            v-if="form.sexe == 'Femme' && age >= 35"
          >
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Age de la ménopause (si applicable)
            </div>
            <v-text-field v-model="form.ageMenopause" outlined></v-text-field>
          </v-col>

          <!-- Vaccin Gardasil fait ?  -->
          <v-col
            cols="12"
            class="mycol"
            v-if="form.sexe == 'Femme' && age >= 25"
          >
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Vaccin Gardasil®️ fait ? (contre le cancer du col de l’utérus)
            </div>
            <v-switch
              v-model="form.vaccinGardasilFait"
              label="J'ai fait le vaccin"
            ></v-switch>
          </v-col>
        </v-row>

        <!-- **** Histoire familiale ***** -->
        <h3 class="mt-10 mb-3">Histoire familiale</h3>

        <v-row>
          <!-- Grossesse mère  -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Comment s’est passée la grossesse de votre mère vous concernant ?
            </div>
            <v-textarea
              v-model="form.grossesseMere"
              :rules="required"
              outlined
              rows="3"
              placeholder="Accident pendant la grossesse, grossesse désirée, présence d’un jumeau, césarienne, voies naturelles, poids et taille à la naissance, prématurité, à terme, post-terme, ustensiles (ventouse, forceps, spatules, …), péridurale, allaitement"
            ></v-textarea>
          </v-col>

          <!-- Avez vous enfants ? -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Avez-vous des enfants ?
            </div>
            <v-switch
              v-model="form.enfants"
              label="J'ai des enfants"
            ></v-switch>
          </v-col>

          <!-- Détails enfants pour hommes -->
          <v-col
            cols="12"
            class="mycol"
            v-if="form.sexe == 'Homme' && form.enfants"
          >
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Combien d'enfants ? Indiquer leur sexe et année de naissance
            </div>
            <v-textarea
              v-model="form.detailsEnfants"
              :rules="required"
              outlined
              rows="2"
            ></v-textarea>
          </v-col>

          <!-- Détails enfants pour femmes -->
          <v-col
            cols="12"
            class="mycol"
            v-if="form.sexe == 'Femme' && form.enfants"
          >
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Veuillez préciser nombre de grossesses, le nombre d’enfants, le
              sexe, les années de naissance. Le déroulement de la grossesse
              (nombre de fœtus par grossesse, y a t-il eu des événements
              médicaux ou des accidents pendant les grossesses? (hypertension,
              diabète, chute/accident, dépistages, infections urinaires))<br />
              Comment se sont déroulés les accouchements? (par les voies
              naturelles, par césarienne? à terme? prématurés? post-terme?
              péridurale? déclenchement? présentation du bébé? utilisation
              d’ustensiles (ventouse, forceps, spatules, …)? déchirure
              périnéale? épisiotomie? hémorragie? poids et taille du (des)
              bébé(s)? dépression post-partum? type d’allaitement? rééducation
              périnéale ou abdominale)
            </div>
            <v-textarea
              v-model="form.detailsEnfants"
              :rules="required"
              outlined
              rows="5"
            ></v-textarea>
          </v-col>

          <!-- Détails enfants pour femmes -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Parmi les membres de votre famille du 1er degré (parents, fratrie,
              enfants, cousins, oncles et tantes), certains ont-ils eu des
              pathologies (problèmes cardiaques, pulmonaires, digestifs,
              génétiques, articulaires, veineux, dermatologiques, hormonaux,
              psychiatriques, cancers, …).
            </div>
            <v-textarea
              v-model="form.pathologiesMembresFamille"
              outlined
              rows="3"
            ></v-textarea>
          </v-col>
        </v-row>

        <!-- **** Examens complémentaires ***** -->
        <h3 class="mt-10 mb-3">Examens complémentaires</h3>
        <v-row>
          <!-- Examens  -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Avez-vous fait certains de ces examens ?
            </div>
            <v-select
              outlined
              v-model="form.examensComplementaires"
              :items="examensItems"
              multiple
            >
            </v-select
          ></v-col>

          <!-- Détails examens  -->
          <v-col
            cols="12"
            class="mycol"
            v-if="form.examensComplementaires.length > 0"
          >
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Précisez les dates et résultats des examens cochés
            </div>
            <v-textarea
              outlined
              v-model="form.detailsExamens"
              rows="3"
              placeholder="Ex: bilan sanguin OK le 01/01/21"
            >
            </v-textarea
          ></v-col>
        </v-row>

        <!-- **** Examens complémentaires ***** -->
        <h3 class="mt-10 mb-3">Ostéopathie</h3>
        <v-row>
          <!-- Membre famille déjà consulté ?  -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Des membres de la famille sont déjà venus me consulter ? Si oui,
              qui ?
            </div>
            <v-text-field outlined v-model="form.membresFamilleDejaConsulte">
            </v-text-field
          ></v-col>

          <!-- Comment connu cabinet ?  -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Par quel biais avez-vous connu le cabinet ?
            </div>
            <v-text-field outlined v-model="form.commentConnuCabinet">
            </v-text-field
          ></v-col>

          <!-- Comment connu cabinet ?  -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Avez-vous déjà consulté en ostéopathie ? Si oui, quand ? où ? et
              pour quel motif ?
            </div>
            <v-text-field outlined v-model="form.dejaConsulteOsteo">
            </v-text-field
          ></v-col>
        </v-row>
      </div>

      <!-- Bloc ancien patient déjà venu -->
      <div v-if="form.nouveauPatient == false">
        <div class="mb-2 font-italic">
          Veuillez mettre à jour les items suivants, si des changements sont
          intervenus dans votre vie depuis la dernière consultation, afin que je
          puisse mettre à jour votre dossier médical svp. Merci d’avance.
        </div>

        <h3 class="mt-8">Informations administratives</h3>
        <v-row>
          <!-- Nom -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Nom du ou de la patiente
            </div>
            <v-text-field
              outlined
              v-model="form.nom"
              :rules="required"
            ></v-text-field>
          </v-col>

          <!-- Prenom-->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Prénom du ou de la patiente
            </div>
            <v-text-field
              outlined
              v-model="form.prenom"
              :rules="required"
            ></v-text-field>
          </v-col>

          <!-- Numéro de téléphone -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Numéro de téléphone portable
            </div>
            <v-text-field
              outlined
              v-model="form.telephone"
              :rules="required"
            ></v-text-field>
          </v-col>

          <!-- Email -->
          <v-col cols="12" md="6" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Adresse email
            </div>
            <v-text-field
              :rules="emailRules"
              outlined
              v-model="form.email"
            ></v-text-field>
          </v-col>

          <!-- Choix des éléments qui ont changé -->
          <v-col cols="12" class="mycol">
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Sélectionnez dans la liste ci-dessous les éléments qui ont changé
              depuis votre dernière consultation.
            </div>
            <v-select
              outlined
              v-model="form.infosAChanger"
              :items="infosAncienPatientItems"
              multiple
            ></v-select>
          </v-col>

          <!--  Loop des éléments qui ont changé -->
          <v-col
            cols="12"
            class="mycol"
            v-for="(changement, index) in form.infosAChanger"
            :key="index"
          >
            <div class="mt-8 mb-3 font-weight-bold">
              <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
              Mise à jour : {{ changement }}
            </div>
            <v-text-field
              outlined
              v-model="form.changements[index]"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <!-- Navbar  -->
      <div class="d-flex mt-10">
        <v-btn
          color="grey lighten-1"
          dark
          depressed
          @click="$store.commit('precedent')"
          ><v-icon class="mr-3">mdi-arrow-left-bold</v-icon> Précédent
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          :loading="loadingValidation"
          depressed
          color="green"
          dark
          >Valider <v-icon class="ml-3">mdi-arrow-right-bold</v-icon></v-btn
        >
      </div>
      <!-- / Navbar  -->

      <v-row><v-col cols="12" class="mt-7"></v-col></v-row>
    </v-form>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  operations,
  traumatismes,
  antecedentsMedicaux,
  examens,
  infosAncienPatient,
} from "../../constantes/selects";

export default {
  data() {
    return {
      emailRules: [
        (v) => !!v || "Requis",
        (v) => /.+@.+\..+/.test(v) || "Adresse email invalide",
      ],
      loadingValidation: false,
      menu: false,
      examensItems: examens,
      operationsItems: operations,
      infosAncienPatientItems: infosAncienPatient,
      traumatismesItems: traumatismes,
      antecedentsMedicauxItems: antecedentsMedicaux,
      required: [(v) => !!v || "Requis"],
      sexeItems: ["Femme", "Homme", "En transition"],
      nouveauPatientItems: [
        { text: "Déjà venu(e)", value: false },
        { text: "Un(e) nouveau(elle) patient(e)", value: true },
      ],
      form: {
        infosAChanger: [],
        nouveauPatient: null,
        // Informations administratives
        nom: "",
        prenom: "",
        sexe: "",
        dateDeNaissance: null,
        adresse: "",
        situationMaritale: "",
        medecinTraitant: "",
        emploi: "",
        emploisPrecedents: "",
        telephone: "",
        email: "",
        taille: "",
        poids: "",
        // Traitements en cours
        medicamenteux: "",
        dispositifsMedicaux: "",
        prendContraception: false,
        contraception: "",
        raisonNonContraception: "",
        dateDernieresRegles: "",
        // Habitudes de vie
        entreprise: "",
        sportsActuels: "",
        sportsPasses: "",
        loisirs: "",
        droitierGaucher: "",
        // Habitudes alimentaires
        typeAlimentation: "",
        typeBoissons: "",
        suiviDiet: false,
        variationsImportantesPoids: "",
        // Antécédents chirurgicaux
        operationsFaites: [],
        datesOperations: "",
        // Antécédents traumatiques
        traumatismesSubis: [],
        datesTraumatismes: "",
        // Antécédents médicaux
        antecedentsMedicaux: [],
        agePremieresRegles: "",
        ageMenopause: "",
        vaccinGardasilFait: false,
        // Histoire familiale
        grossesseMere: "",
        enfants: false,
        detailsEnfants: "",
        pathologiesMembresFamille: "",
        // Examens complémentaires
        examensComplementaires: [],
        detailsExamens: "",
        // Ostéopathie
        membresFamilleDejaConsulte: "",
        commentConnuCabinet: "",
        dejaConsulteOsteo: "",
        // Changements
        changements: [],
      },
    };
  },

  computed: {
    age() {
      if (this.form.dateDeNaissance)
        return dayjs().diff(
          dayjs(this.form.dateDeNaissance, "YYYY-MM-DD"),
          "year"
        );
      return null;
    },
    dateDeNaissanceFormatee() {
      if (this.form.dateDeNaissance)
        return dayjs(this.form.dateDeNaissance, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
      return null;
    },
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },

  methods: {
    saveDOB(date) {
      this.$refs.menu.save(date);
    },
    async save() {
      // Check la validité du formulaire
      if (this.form.nouveauPatient != null && this.$refs.form.validate()) {
        this.loadingValidation = true;
        this.$emit("save", this.form);
      } else {
        // Montrer popup erreur
        this.$swal.fire({
          icon: "warning",
          title: "Informations manquantes",
          text: "Veuillez vérifier que toutes les informations demandées ont bien été renseignées, merci.",
        });
      }
    },
  },
};
</script>

<style scoped>
.mycol {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
