<template>
  <div>
    <h2 class="mb-6">Description du motif de consultation</h2>
    <form class="v-form" @submit.prevent="suivant">
      <!-- Zone pas grossesse  -->
      <div v-if="!grossesse">
        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Depuis quand les symptômes sont-ils apparus?
        </div>
        <v-text-field
          outlined
          v-model="form.depuisQuand"
          required
        ></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Est-ce la première fois que vous les ressentez ? Si non, cet épisode
          est-il différent des précédents et en quoi ?
        </div>
        <v-textarea
          outlined
          v-model="form.premiereFois"
          rows="3"
          required
        ></v-textarea>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Qu’est-ce qui a provoqué vos douleurs? Y a t-il un élément déclencheur
          à cet épisode? (mouvement, action, accident, préoccupation, élément
          inhabituel)
        </div>
        <v-textarea
          outlined
          v-model="form.elementDeclencheur"
          rows="3"
          required
        ></v-textarea>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Comment s’est installé cet épisode? Brutalement? Progressivement? ...
        </div>
        <v-text-field
          outlined
          v-model="form.installation"
          required
        ></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Où se localise la douleur plus précisément ?
        </div>
        <v-text-field
          outlined
          v-model="form.localisationDouleur"
          required
        ></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Diffuse-t-elle ailleurs ?
        </div>
        <v-text-field outlined v-model="form.diffuse" required></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Existe-t-il des mouvements, des positionnements ou des facteurs
          aggravant votre douleur ?
        </div>
        <v-text-field
          outlined
          v-model="form.mouvementsAggravants"
          required
        ></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Existe-t-il des mouvements, des positionnements ou des facteurs
          soulageant votre douleur ?
        </div>
        <v-text-field
          outlined
          v-model="form.mouvementsQuiSoulagent"
          required
        ></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Cette douleur vous réveille-t-elle la nuit? Si oui, tant la douleur
          est intense? ou lorsque vous bougez ?
        </div>
        <v-textarea outlined v-model="form.nuit" rows="2" required></v-textarea>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Veuillez évaluer cette douleur sur 10 svp. (0= absence de gêne, 10=
          douleur intolérable)
        </div>
        <v-text-field
          outlined
          v-model="form.evaluationDouleur"
          required
        ></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Avez-vous essayé un traitement, une crème, un médicament pour atténuer
          les choses?
        </div>
        <v-text-field
          outlined
          v-model="form.medicament"
          required
        ></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Les douleurs sont-elles: De pire en pire? Stable? En train de
          disparaître?
        </div>
        <v-text-field
          outlined
          v-model="form.evolutionDouleurs"
          required
        ></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Les douleurs sont-elles plus intenses le matin? Le soir? La nuit? Au
          travail? Au repos? Le week-end?
        </div>
        <v-text-field
          outlined
          v-model="form.momentDouleurs"
          required
        ></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Les douleurs sont-elles permanentes ou intermittentes ?
        </div>
        <v-text-field
          outlined
          v-model="form.douleursPermanentesOuIntermitentes"
          required
        ></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Quel est le type de douleur? ça pique? ça lance? Ça tord? ça serre?
          c’est un poids? …?
        </div>
        <v-text-field
          outlined
          v-model="form.typeDouleur"
          required
        ></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Ressentez-vous des symptômes que vous associez à cet épisode? Troubles
          digestifs? Troubles urinaires? Troubles du transit? Oppressions dans
          la poitrine? Angoisses? Palpitations? Maux de tête? Nausées? Vertiges?
          Fourmillements?
        </div>
        <v-text-field outlined v-model="form.symptomes" required></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Trouvez-vous une modification de votre état global en ce moment? Une
          fatigue, une perte de poids ou d’appétit inexpliqués ?
        </div>
        <v-text-field
          outlined
          v-model="form.modificationEtatGlobal"
          required
        ></v-text-field>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Voyez-vous des signes d’inflammation sur la zone douloureuse? Chaleur
          - rougeur - gonflement.
        </div>
        <v-text-field
          outlined
          v-model="form.inflammation"
          required
        ></v-text-field>
      </div>
      <!-- / Zone pas grossesse  -->

      <!-- Zone spéciale grossesse  -->
      <div v-else>
        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Quel est le terme de la grossesse? Combien de kilos avez-vous pris ou
          perdus depuis le début de votre grossesse?
        </div>
        <v-textarea
          outlined
          v-model="form.termeGrossesse"
          rows="3"
          required
        ></v-textarea>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Avez-vous d’autres manifestations ? Hypertension artérielle, diabète
          gestationnel, constipation, hémorroïdes, oedèmes ...
        </div>
        <v-textarea
          outlined
          v-model="form.autresManifestations"
          rows="3"
          required
        ></v-textarea>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Date de la dernière consultation avec votre sage-femme ou gynécologue,
          et bilan de cette consultation.
        </div>
        <v-textarea
          outlined
          v-model="form.derniereConsultation"
          rows="3"
          required
        ></v-textarea>

        <div class="mt-8 mb-3">
          <v-icon class="mb-1 mr-4">mdi-arrow-right-circle</v-icon>
          Dernière échographie et bilan de cet examen.
        </div>
        <v-textarea
          outlined
          v-model="form.derniereEchographie"
          rows="3"
          required
        ></v-textarea>
      </div>
      <!-- / Zone spéciale grossesse  -->

      <!-- Navbar  -->
      <div class="d-flex mt-10">
        <v-btn
          color="grey lighten-1"
          dark
          depressed
          @click="$store.commit('precedent')"
          ><v-icon class="mr-3">mdi-arrow-left-bold</v-icon> Précédent
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn type="submit" color="green" dark depressed
          >Suivant <v-icon class="ml-3">mdi-arrow-right-bold</v-icon></v-btn
        >
      </div>
      <!-- / Navbar  -->
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        depuisQuand: "",
        premiereFois: "",
        elementDeclencheur: "",
        installation: "",
        localisationDouleur: "",
        diffuse: "",
        mouvementsAggravants: "",
        mouvementsQuiSoulagent: "",
        nuit: "",
        evaluationDouleur: "",
        medicament: "",
        evolutionDouleurs: "",
        momentDouleurs: "",
        douleursPermanentesOuIntermitentes: "",
        typeDouleur: "",
        symptomes: "",
        modificationEtatGlobal: "",
        inflammation: "",
        termeGrossesse: "",
        autresManifestations: "",
        derniereConsultation: "",
        derniereEchographie: "",
      },
    };
  },

  computed: {
    grossesse() {
      return this.$store.state.consultation.motif == "Suivi de grossesse";
    },
  },

  methods: {
    suivant() {
      this.$store.commit("updateConsultation", this.form);
      this.$store.commit("motifRempli");
      this.$store.commit("suivant");
    },
  },
};
</script>
